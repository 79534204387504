<template>
  <esmp-link :to="url" class="additional-documents-item">
    <esmp-icon :name="icon" class="additional-documents-item__icon" />

    <div class="additional-documents-item__title">
      <span
        v-if="maxSymbols"
        :title="title"
      >
        {{ title | truncate(maxSymbols) }}
      </span>
      <span v-if="!maxSymbols">{{ title }}</span>
    </div>

    <slot name="action-buttons">
      <esmp-icon name="arrow-right" class="additional-documents-item__icon additional-documents-item__button" />
    </slot>
  </esmp-link>
</template>

<script>
export default {
  name: 'AdditionalDocumentsItem',

  props: {
    title: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: 'template',
    },

    maxSymbols: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.additional-documents-item {
  position: relative;
  display: flex;
  align-items: center;
  /*height: 72px;*/
  padding: 16px;
  background: $color-grayscale-05;
  border-radius: 12px;
  text-decoration: none;

  &,
  &__action-button {
    color: $color-black;
    transition: color $base-animation-time $base-animation-function;
  }

  &__icon,
  &__action-button {
    width: 24px;
    flex-shrink: 0;
  }

  &__title {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    line-height: 20px;
    flex-grow: 1;
  }

  &__action-button {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      color: $color-client-portal-logo;
    }
  }

  &:hover {
    color: $color-client-portal-logo;
  }

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
</style>
