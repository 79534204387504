<template>
  <section class="additional-documents block block--rounded block--white">
    <div class="additional-documents__title block__title">
      <h3>{{ title }}</h3>

      <esmp-button
        v-if="isSortable && localItems.length"
        class="additional-documents__sort-button"
        view="function"
        icon="24-settings"
        title="Включить режим сортировки"
        @click.stop="toggleSortMode"
      />

      <esmp-link
        v-if="additionalLinkTitle"
        :to="additionalLinkUrl"
        class="additional-documents__additional-link"
      >
        <span>{{ additionalLinkTitle }}</span>

        <esmp-icon name="arrow-long" />
      </esmp-link>
    </div>

    <draggable
      class="additional-documents__body"
      v-model="localItems"
      :options="{ disabled: !isDraggable }"
      handle=".additional-documents__item--draggable"
    >
      <div
        v-for="(document, index) in localItems"
        :key="`document_${document.title}_${index}`"
        :class="[
          'additional-documents__item',
          {'additional-documents__item--draggable': isDraggable}
        ]"
      >
        <additional-documents-item
          :title="document.title"
          :url="document.url"
          :max-symbols="48"
        />
      </div>
    </draggable>
  </section>
</template>

<script>
import draggable from 'vuedraggable';
import AdditionalDocumentsItem from './AdditionalDocumentsItem.vue';

export default {
  name: 'AdditionalDocuments',

  components: { draggable, AdditionalDocumentsItem },

  props: {
    title: {
      type: String,
      default: 'Дополнительные ссылки',
    },

    additionalLinkTitle: {
      type: String,
      default: null,
    },

    additionalLinkUrl: {
      type: String,
      default: '/',
    },

    items: {
      type: Array,
      default: () => ([]),
    },

    isSortable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDraggable: false,
    };
  },
  computed: {
    localItems: {
      get() {
        return this.items;
      },
      set(val) {
        this.$emit('sort-templates-order', val);
      },
    },
  },
  methods: {
    toggleSortMode() {
      this.isDraggable = !this.isDraggable;
    },
  },
};
</script>

<style lang="scss">
.additional-documents {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  @include for-size(phone-landscape-down) {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  @include for-size(phone-portrait-down) {
    padding: 20px;
    width: 100vw;
    margin-left: -20px;
  }

  &__additional-link {
    display: flex;
    align-items: center;

    span {
      padding-right: 4px;
    }
  }

  &__body {

    @include for-size(phone-landscape-down) {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 20px;
    }

    @include for-size(phone-portrait-down) {
      flex-direction: column;
      gap: unset;
    }
  }

  &__item {
    transition:
      width $base-animation,
      margin-left $base-animation,
      background-color $base-animation,
      color $base-animation !important;

    &:before {
      content: '=';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -$draggable-element-width;
      font-size: 26px;
      visibility: hidden;
      opacity: 0;
      transition: opacity $base-animation;
      @include for-size(phone-landscape-down) {
        top: -$draggable-element-width;
        transform: translateX(-50%);
        left: 50%;
      }
      @include for-size(phone-portrait-down) {
        top: 50%;
        transform: translateY(-50%);
        left: -$draggable-element-width;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
      @include for-size(phone-landscape-down) {
        margin-bottom: 0;
      }

      @include for-size(phone-portrait-down) {
        margin-bottom: 16px;
      }
    }

    @include for-size(phone-landscape-down) {
      width: calc(25% - 10px);
    }

    @include for-size(phone-portrait-down) {
      width: 100%;
    }

    &--draggable {
      width: calc(100% - #{$draggable-element-width});
      margin-left: $draggable-element-width;
      position: relative;
      cursor: move !important;

      @include for-size(phone-landscape-down) {
        width: calc(25% - 10px);
        margin-top: $draggable-element-width;
        margin-left: 0;
      }

      @include for-size(phone-portrait-down) {
        width: calc(100% - #{$draggable-element-width});
        margin-left: $draggable-element-width;
      }

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .additional-documents-item {
    @include for-size(phone-landscape-down) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }

    @include for-size(phone-portrait-down) {
      align-items: center;
      flex-direction: row;
    }

    &__title {
      @include for-size(phone-landscape-down) {
        padding: 0;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      @include for-size(phone-portrait-down) {
        margin: 0;
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &__button {
      @include for-size(phone-landscape-down) {
        align-self: flex-end;
      }
      @include for-size(phone-portrait-down) {
        align-self: unset;
      }
    }
  }
}
</style>
